import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { Container } from 'react-bootstrap';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container className="pt-5 text-center">
      <h1 className="mb-4">404: Not Found</h1>
      <p>Sorry, this page does not exist.</p>
      <a href="/">Return to the homepage.</a>
    </Container>
  </Layout>
);

export default NotFoundPage;
